import { render, staticRenderFns } from "./Certificates.html?vue&type=template&id=2c49d34e&scoped=true&"
import script from "./Certificates.vue?vue&type=script&lang=js&"
export * from "./Certificates.vue?vue&type=script&lang=js&"
import style0 from "./Certificates.scss?vue&type=style&index=0&id=2c49d34e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c49d34e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCol,VDivider,VRow})
